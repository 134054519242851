
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import Cell from './cell'
  import { Route } from 'vue-router'
  import { isValidNumber } from '@/utils/general'

@Component
  export default class ProgressCell extends Cell {
  @Prop({ type: Boolean, default: true }) round!: any
  progress = 0
  supervisor = false

  get color () {
    const { item, supervisor } = this
    if (!item) {
      this.progress = 0
      return 'grey'
    }

    if (supervisor) {
      this.progress = item?.metadata?.totalCategoriesValidated || 0
    } else {
      this.progress = isValidNumber(item.metadata?.progress) ? item?.metadata?.progress : 0
    }

    switch (true) {
      case (this.progress >= 0 && this.progress < 6.4):
        return 'grey'
      case (this.progress >= 6.6 && this.progress < 35):
        return 'red'
      case (this.progress >= 35 && this.progress < 50):
        return 'orange'
      case (this.progress >= 50 && this.progress < 72):
        return 'yellow'
      case (this.progress >= 72 && this.progress < 93):
        return 'green'
      default:
        return 'primary'
    }
  }

  @Watch('$route', { immediate: true, deep: true })
  onChangeRoute (val: Route): void {
    this.supervisor = val.name.includes('supervisor') || val.params?.role === 'supervisor'
  }
  }
